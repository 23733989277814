import { IBlogPage, IPageMeta, IResponse, TLang } from '@/types'
import { API_ENDPOINT } from '@/constants'
import endpoints from '@/apis/endpoints'

import { generatePath } from './urls'

export async function getBlogPostsPage(lang: TLang) {
  const result = await fetch(
    generatePath(`${API_ENDPOINT}/api/${lang}/${endpoints.blog}`, undefined, [
      {
        page: '1',
        size: '9',
      },
    ]),
    {
      next: { revalidate: 24 * 3600 },
      method: 'GET',
    },
  )

  const data: IResponse<IBlogPage> & { meta: IPageMeta } = await result.json()

  return data
}
