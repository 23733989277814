import React from 'react'
import { useRouter } from 'next-intl/client'
import classNames from 'classnames'

import { Accordion, Button } from '../'
import { useLanguages } from '@/hooks'
import { IFaqType } from '@/types'
import { faqPath } from '@/utils'

interface IFaqSection {
  title?: string
  ignore?: boolean
  subTitle?: string
  items?: IFaqType[]
  hasMore?: boolean
  isLoading?: boolean
}

export const FAQSection: React.FC<IFaqSection> = ({
  title,
  subTitle,
  items = [],
  ignore = false,
  hasMore = true,
}) => {
  const router = useRouter()
  const { t } = useLanguages()

  if (!items.length && !ignore) return <section className="faq" />

  return (
    <section
      id="faq"
      className={classNames('faq', {
        container: ignore,
      })}
    >
      {title ? (
        <h2
          className="section-title text-center text-m-start"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      ) : (
        <h2 className="section-title text-center text-m-start">
          {t('faqTitle')}
        </h2>
      )}
      {subTitle ? (
        <h3
          className="section-subtitle text-center text-m-start"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      ) : (
        <h3 className="section-subtitle text-center text-m-start">
          {t('faqDesc')}
        </h3>
      )}

      <Accordion items={items} />
      {hasMore && (
        <div className="d-flex justify-center">
          <Button className="btn big" onClick={() => router.push(faqPath)}>
            {t('readMore')}
          </Button>
        </div>
      )}
    </section>
  )
}
